import {CalendarWithDate} from '@/components/common/advanced-header/icons/calendar-with-date/CalendarWithDate';
import {ANCHORS as WorkMailAnchors} from '@/components/page/vk-work-mail/const';
import {LINKS, PAGES} from '@/constants';

import {Targets} from '../../../../types/common';
import BlogSvg from './icons/blog.svg';
import BoardSvg from './icons/board.svg';
import CloudPlusSvg from './icons/cloud_plus.svg';
import DiskSvg from './icons/disk.svg';
import DocumentationSvg from './icons/documentation.svg';
import DocumentsSvg from './icons/documents.svg';
import DownloadSvg from './icons/download.svg';
import EducationSvg from './icons/education.svg';
import MailSvg from './icons/mail.svg';
import MessengerSvg from './icons/messenger.svg';
import NotebookSvg from './icons/notebook.svg';
import OnpremSvg from './icons/onprem.svg';
// import OrgStructureSvg from './icons/orgstructure.svg';
import RetailSvg from './icons/retail.svg';
// import SaasSvg from './icons/saas.svg';
import SecuritySvg from './icons/security.svg';
import SuperAppSvg from './icons/superapp.svg';
import SupportSvg from './icons/support.svg';
import TasksSvg from './icons/tasks.svg';
import VideoConferenceSvg from './icons/videoconference.svg';
import type {AdvancedMenu} from './types';

export enum MenuLinks {
	sectionServices = 'section_services',
	sectionSolutions = 'section_solutions',
	sectionClients = 'section_clients',
	sectionTariff = 'section_tariff',
	sectionDownload = 'section_download',
	sectionPartners = 'section_partners',
}
export enum SectionServices {
	serviceMail = 'service_mail',
	serviceDisk = 'service_disk',
	serviceDocuments = 'service_documents',
	serviceTasks = 'service_tasks',
	serviceOrgStructure = 'service_orgStructure',
	serviceMessenger = 'service_messenger',
	serviceVideoConference = 'service_video_conference',
	serviceCalendar = 'service_calender',
	serviceSuperApp = 'service_super_app',
	serviceBoard = 'service_board',
	serviceNotebook = 'service_notebook',
}
export enum SectionSolutions {
	solutionEducation = 'solution_education',
	solutionSaas = 'solution_saas',
	solutionSaasModels = 'solution_saas_models',
	solutionOnprem = 'solution_onprem',
	solutionRetail = 'solution_retail',
}
export enum SectionClients {
	clientBlog = 'client_blog',
	clientDocumentation = 'client_documentation',
	clientDownloadVKTeams = 'client_download_vk_teams',
	clientSupport = 'client_support',
	clientLearning = 'client_learning',
	clientSecurity = 'client_security',
}

// eslint-disable-next-line max-lines-per-function
export const useGetMenuMap = (): AdvancedMenu[] => {
	return [
		{
			title: 'Сервисы',
			id: MenuLinks.sectionServices,
			items: [
				{
					id: SectionServices.serviceMail,
					titleItem: 'Почта',
					description: (
						<>
							Электронная почта на&nbsp;домене <br />
							для сотрудников
						</>
					),
					icon: MailSvg,
					href: PAGES.workMail.href,
					projectHref: PAGES.workMail.projectHref,
				},
				{
					id: SectionServices.serviceMessenger,
					titleItem: 'Мессенджер',
					description: <>Корпоративный мессенджер для оперативной коммуникации в&nbsp;команде</>,
					icon: MessengerSvg,
					href: PAGES.workMessenger.href,
					projectHref: PAGES.workMessenger.projectHref,
				},
				{
					id: SectionServices.serviceVideoConference,
					titleItem: 'Видеоконференции',
					description: (
						<>
							Аудио- и&nbsp;видео- звонки <br />
							для онлайн-встреч
						</>
					),
					icon: VideoConferenceSvg,
					href: PAGES.meetings.href,
					projectHref: PAGES.meetings.projectHref,
				},
				{
					id: SectionServices.serviceCalendar,
					titleItem: 'Календарь',
					description: (
						<>
							Планирование встреч <br />
							и&nbsp;звонков
						</>
					),
					icon: () => <CalendarWithDate />,
					href: `${PAGES.workMail.href}#${WorkMailAnchors.CALENDAR}`,
					projectHref: PAGES.workMail.projectHref,
				},
				{
					id: SectionServices.serviceDisk,
					titleItem: 'Диск',
					description: (
						<>Облачное хранилище для файлов и&nbsp;совместной работы с&nbsp;документами</>
					),
					icon: DiskSvg,
					href: PAGES.workDisk.href,
					projectHref: PAGES.workDisk.projectHref,
				},
				{
					id: SectionServices.serviceDocuments,
					titleItem: 'Документы',
					description: <>Совместное редактирование документов, таблиц и&nbsp;презентаций</>,
					icon: DocumentsSvg,
					href: PAGES.office.href,
					projectHref: PAGES.office.projectHref,
				},
				{
					id: SectionServices.serviceTasks,
					titleItem: 'Задачи',
					description: <>Планирование и&nbsp;работа с&nbsp;задачами</>,
					icon: TasksSvg,
					href: PAGES.tasks.href,
					projectHref: PAGES.tasks.projectHref,
				},
				{
					id: SectionServices.serviceBoard,
					titleItem: 'Доска',
					description: <>Интерактивная доска для совместной работы</>,
					icon: BoardSvg,
					href: PAGES.board.href,
					projectHref: PAGES.board.projectHref,
				},
				{
					id: SectionServices.serviceNotebook,
					titleItem: 'Заметки',
					description: <>Создание текстовых заметок</>,
					icon: NotebookSvg,
					href: PAGES.notebook.href,
					projectHref: PAGES.notebook.projectHref,
				},
				// {
				// 	id: SectionServices.serviceOrgStructure,
				// 	titleItem: 'Оргструктура',
				// 	description: <>Инструмент для создания организационной структуры компании</>,
				// 	icon: OrgStructureSvg,
				// 	href: PAGES.workDisk.href,
				// 	projectHref: PAGES.workDisk.projectHref,
				// },
				{
					id: SectionServices.serviceSuperApp,
					titleItem: 'Приложение VK\u00A0Teams',
					description: <>Приложение для доступа к&nbsp;сервисам платформы VK&nbsp;WorkSpace</>,
					icon: SuperAppSvg,
					href: PAGES.teams.href,
					projectHref: PAGES.teams.projectHref,
				},
			],
		},
		{
			title: 'Решения',
			id: MenuLinks.sectionSolutions,
			items: [
				{
					id: SectionSolutions.solutionSaasModels,
					titleItem: 'Для размещения в облаке',
					description: <>Сервисы для совместной работы по&nbsp;модели SaaS</>,
					icon: CloudPlusSvg,
					href: PAGES.saas.href,
				},
				{
					id: SectionSolutions.solutionEducation,
					titleItem: 'Для образования',
					description: <>Сервисы коммуникаций для преподавателей и&nbsp;учащихся</>,
					icon: EducationSvg,
					href: PAGES.education.href,
				},
				{
					id: SectionSolutions.solutionOnprem,
					titleItem: 'Для размещения на\u00A0сервере',
					description: <>Сервисы для совместной работы по&nbsp;модели On-premises</>,
					icon: OnpremSvg,
					href: PAGES.onpremise.href,
				},
				{
					id: SectionSolutions.solutionRetail,
					titleItem: 'Для розничной торговли',
					description: <>Автоматизация взаимодействия десятков и&nbsp;сотен сотрудников</>,
					icon: RetailSvg,
					href: PAGES.retail.href,
				},
			],
		},
		{
			title: 'Клиентам',
			id: MenuLinks.sectionClients,
			items: [
				{
					id: SectionClients.clientBlog,
					titleItem: 'Блог',
					description: <>Журнал о&nbsp;том, как технологии помогают развивать бизнес</>,
					icon: BlogSvg,
					href: LINKS.bizBlog,
					target: Targets.blank,
				},
				{
					id: SectionClients.clientDocumentation,
					titleItem: 'Документация',
					description: <>Инструкции для администраторов и&nbsp;пользователей</>,
					icon: DocumentationSvg,
					href: PAGES.docs.href,
				},
				{
					id: SectionClients.clientDownloadVKTeams,
					titleItem: 'Скачать VK\u00A0Teams',
					description: <>Автоматизация взаимодействия десятков и&nbsp;сотен сотрудников</>,
					icon: DownloadSvg,
					href: PAGES.teamsDownload.href,
				},
				{
					id: SectionClients.clientSupport,
					titleItem: 'Поддержка',
					description: <>Форма для обращений в&nbsp;техническую поддержку</>,
					icon: SupportSvg,
					href: LINKS.docsSaasSupport,
					target: Targets.blank,
				},
				{
					id: SectionClients.clientSecurity,
					titleItem: 'Безопасность',
					description: <>Информационная безопасность и&nbsp;отказоустойчивость</>,
					icon: SecuritySvg,
					href: PAGES.security.href,
				},
			],
		},
		{
			title: 'Партнерам',
			id: MenuLinks.sectionPartners,
			href: PAGES.partners.href,
			withBorder: false,
		},
		{
			title: 'Тарифы',
			id: MenuLinks.sectionTariff,
			href: PAGES.tariffs.href,
		},
	];
};
