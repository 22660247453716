import classNames from 'classnames';
import React from 'react';

import type {Targets} from '../../../../../../types/common';
import styles from './NavbarMenuItem.module.css';
interface NavbarMenuItemProps {
	title: string;
	href: string;
	target?: Targets;
	className?: string;
	withBorder?: boolean;
}

export function NavbarMenuItem({href, target, title, className, withBorder = true}: NavbarMenuItemProps) {
	return (
		<a
			href={href}
			target={target}
			className={classNames(styles.root, className, {
				[styles.border]: withBorder,
			})}
		>
			{title}
		</a>
	);
}
