import classNames from 'classnames';
import React from 'react';

import {DropdownContainer} from '@/components/base/dropdown/DropdownContainer';
import {DropdownItem} from '@/components/base/dropdown/DropdownItem';
import {DropdownToggle} from '@/components/base/dropdown/DropdownToggle';
import {NavbarItem} from '@/components/common/advanced-header/components/navbar-item/NavbarItem';
import {NavbarMenuItem} from '@/components/common/advanced-header/components/navbar-menu-item/NavbarMenuItem';
import {Overlay} from '@/components/common/advanced-header/components/overlay/Overlay';
import type {AdvancedMenu} from '@/components/common/advanced-header/types';

import {Toggle} from '../toggle/Toggle';
import styles from './Navbar.module.css';

interface NavbarProps {
	menu: AdvancedMenu[];
}
export function Navbar({menu}: NavbarProps) {
	return (
		<div className={styles.wrapper}>
			{menu.map(({id, title, items, href, target, withBorder}) => {
				if (items && items.length > 0) {
					return (
						<Overlay id={id} key={id}>
							<DropdownToggle Component={Toggle}>{title}</DropdownToggle>
							<DropdownContainer aria-label={title} className={styles.container}>
								<div className={styles.wrapperInnerDropdown}>
									<div
										className={classNames(styles.innerDropdown, {
											[styles.innerDropdownExtra]: items.length > 6,
											[styles.innerDropdownFlowColumn]: items.length === 4,
										})}
									>
										{items.map((item) => {
											return (
												<DropdownItem key={item.id} className={styles.dropdownItem}>
													<NavbarItem {...item} />
												</DropdownItem>
											);
										})}
									</div>
								</div>
							</DropdownContainer>
						</Overlay>
					);
				}

				if (href) {
					return (
						<NavbarMenuItem
							key={id}
							title={title}
							href={href}
							target={target}
							withBorder={withBorder}
						/>
					);
				}

				return '';
			})}
		</div>
	);
}
