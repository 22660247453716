import {Observer} from 'mobx-react';

import {Button} from '@/components/base/button/Button';
import {FEATURES} from '@/feature-flags';
import {useAuthButtonActions} from '@/hooks/use-auth-button-actions';

import styles from './HeaderAuthLinks.module.css';

interface HeaderAuthLinksProps {
	hasDomainButtonText?: string;
	showProjectButton?: boolean;
}

export function HeaderAuthLinks({
	hasDomainButtonText = 'Панель управления',
	showProjectButton,
}: HeaderAuthLinksProps) {
	const {
		hasDomain,
		isAuth,
		linkToAdmin,
		linkToAdminTooltip,
		featureService,
		handleOpenCreateProjectDialog,
		handleOpenLoginDialog,
		linkToAdminOnClick,
	} = useAuthButtonActions('header');

	if (isAuth) {
		if (hasDomain) {
			return (
				<Button
					as="a"
					href={linkToAdmin}
					title={linkToAdminTooltip}
					className={styles.root}
					onClick={linkToAdminOnClick}
				>
					{hasDomainButtonText}
				</Button>
			);
		}

		return showProjectButton ? (
			<Observer>
				{() => (
					<Button
						disabled={!featureService.isServiceStarted}
						className={styles.root}
						onClick={handleOpenCreateProjectDialog}
					>
						<span suppressHydrationWarning>{FEATURES.CREATE_LK_BUTTON_TEXT}</span>
					</Button>
				)}
			</Observer>
		) : null;
	}

	return (
		<Button className={styles.root} onClick={handleOpenLoginDialog}>
			Попробовать
		</Button>
	);
}
